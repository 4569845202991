@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');

.homeSinglePage {
  background-image: url("/src/img/bc.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}

.homeSinglePage {
  /* font-family: 'Roboto', cursive; */
}